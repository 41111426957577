





























import { ref, defineComponent } from '@vue/composition-api';
import LicenseForm from '@/components/forms/license-form/index.vue';

export default defineComponent({
  components: {
    LicenseForm,
  },
  setup() {
    const dialog = ref(false);
    return {
      dialog,
    };
  },
});
